import React, { useState, useEffect, SyntheticEvent } from 'react';
import { Link, Navigate } from 'react-router-dom';
import axios from 'axios';

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [error, setError] = useState('');
    const [branchName, setBranchName] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            const { data } = await axios.post('https://api.bayaanedu.com/users/login', {
                email,
                password
            }, { withCredentials: true });
            document.cookie = `token=${data.token}; path=/`;
            setUserName(`${data.first_name} ${data.last_name}`);
            axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
            fetchUserRole(); // Fetch user role after successful login
        } catch (error) {
            setError('Login failed: Invalid credentials');
        }
    };

    const fetchUserRole = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get('https://api.bayaanedu.com/users/user');
            setUserRole(data.user.role); // Ensure you access the role correctly from the response
            console.log('User role:', data.user.role); // Verify role in console
            setAuthenticated(true); // Set authenticated to true after successful login
        } catch (error) {
            console.error('Failed to fetch user role:', error);
            setError('Failed to fetch user role');
        } finally {
            setLoading(false);
        }
    };

    if (authenticated) {
        return <Navigate to="/dashboard" />; // Navigate to dashboard if authenticated
    }
    const getLogoImage = () => {
        switch (branchName) {
            case 'Mustaqbal university':
                return '/images/mustaqbal_university-removebg-preview.png';
            case 'Bayan':
                return '/images/bayaan_-removebg-preview.png';
            default:
                return '/images/mustaqbal_university-removebg-preview.png';
        }
    };
    return (
        <div className="container rounded-lg border p-4 bg-white" style={{ maxWidth: '400px' }}>
            <div className="text-center mb-4">
                                <img
                                    src={getLogoImage() || ''}
                                    alt={`${branchName} Logo`}
                                    className="logoIcon"
                                    style={{ width: '80px' }}
                  />
                <h1 className="h3 mt-2 mb-3 fw-normal">Welcome</h1>
                <p>To login, enter your email and password</p>
            </div>
            <div className="row justify-content-center">
                <div className="col">
                    <form onSubmit={submit}>
                        {error && <div className="alert alert-danger">{error}</div>}
                        <div className="form-floating mb-3">
                            <input
                                type="email"
                                className="form-control form-control-sm"
                                id="floatingInput"
                                placeholder="name@example.com"
                                onChange={e => setEmail(e.target.value)}
                                required
                            />
                            <label htmlFor="floatingInput">Email address</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="password"
                                className="form-control form-control-sm"
                                id="floatingPassword"
                                placeholder="Password"
                                onChange={e => setPassword(e.target.value)}
                                required
                            />
                            <label htmlFor="floatingPassword">Password</label>
                        </div>
                        <div className="mb-3">
                            <Link to="/forgot">Forgot Password?</Link>
                        </div>
                        <button className="w-100 btn btn-lg btn-primary" type="submit">Login</button>
                    </form>
                </div>
            </div>
            {/* Conditionally render the user's name if it's available */}
            {userName && <p className="mt-3 text-center">Welcome, {userName}!</p>}
            {/* Conditionally render content based on userRole */}
            {userRole === 'admin' && (
                <div className="text-center mt-3">
                    <p>You have admin privileges.</p>
                    {/* Add admin specific content or redirect logic here */}
                </div>
            )}
            {userRole === 'super_admin' && (
                <div className="text-center mt-3">
                    <p>You have super admin privileges.</p>
                    {/* Add super admin specific content or redirect logic here */}
                </div>
            )}
            {/* Loading indicator */}
            {loading && (
                <div className="d-flex justify-content-center mt-3">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Login;
