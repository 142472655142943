import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RootState } from '../Redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../Redux/authSlice';
import { Navigate } from 'react-router-dom';

const CreateSalaryForm: React.FC = () => {
    const [teacherId, setTeacherId] = useState<number | null>(null); // Store teacher ID instead of name
    const [month, setMonth] = useState<string>('');
    const [amount, setAmount] = useState<string>('');
    const [datePaid, setDatePaid] = useState<string>('');
    const [teachers, setTeachers] = useState<{ id: number; name: string }[]>([]);
    const [showAlert, setShowAlert] = useState<boolean>(false); // State for showing alert
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                const { data } = await axios.get('https://api.bayaanedu.com/users/user');
                dispatch(setAuth(true));
            } catch (e) {
                dispatch(setAuth(false));
            }
        };

        fetchData();
    }, [dispatch]);

    useEffect(() => {
        const fetchTeachers = async () => {
            try {
                const response = await axios.get('https://api.bayaanedu.com/Branches/teachers/');
                setTeachers(response.data); // Assuming response.data is an array of objects with 'id' and 'name' properties
            } catch (error) {
                console.error('Error fetching teachers:', error);
            }
        };

        fetchTeachers();
    }, []);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
    
        const newSalary = {
            teacher: teacherId, // Send teacher ID instead of name
            month: month,
            amount: amount,
            date_paid: datePaid
        };
    
        console.log('Submitting new salary:', newSalary); // Log the data being sent to the server
    
        try {
            const response = await axios.post('https://api.bayaanedu.com/fees/salaries/', newSalary, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            console.log('New salary created:', response.data);
            setShowAlert(true); // Show alert after successful submission
            // Optionally, you can redirect or show a success message here
            <Navigate to='/salaries'/> // Navigate to salaries page after successful submission
        } catch (error) {
            console.error('Error creating salary:', error);
            // Handle error, display error messages to the user if needed
        }
    };

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    return (
        <div className="container">
            <div className="row justify-content-center mt-5">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title text-center mb-4">Create New Salary</h2>
                            {showAlert && (
                                <div className="alert alert-success" role="alert">
                                    Salary added successfully!
                                </div>
                            )}
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="teacherName" className="form-label">
                                        Teacher Name
                                    </label>
                                    <select
                                        className="form-select"
                                        id="teacherName"
                                        value={teacherId || ''}
                                        onChange={(e) => setTeacherId(parseInt(e.target.value))}
                                        required
                                    >
                                        <option value="">Select Teacher Name</option>
                                        {teachers.map((teacher) => (
                                            <option key={teacher.id} value={teacher.id}>
                                                {teacher.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="month" className="form-label">
                                        Month
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="month"
                                        value={month}
                                        onChange={(e) => setMonth(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="amount" className="form-label">
                                        Amount
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="amount"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="datePaid" className="form-label">
                                        Date Paid
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="datePaid"
                                        value={datePaid}
                                        onChange={(e) => setDatePaid(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="d-grid">
                                    <button type="submit" className="btn btn-primary btn-block">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateSalaryForm;
