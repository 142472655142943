import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../Redux/store';
import { setAuth } from '../Redux/authSlice';
import axios from 'axios';
import jsPDF from 'jspdf';
import "../../App.css";

interface Fee {
  id: number;
  student_name: string; 
  month: string;
  amount_paid: string;
  date_paid: string;
  enrolled_class: string;
  balance: string;
  receipt_number: number;
}

export default function DetailFees() {
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '' });
  const auth = useSelector((state: RootState) => state.auth.value);

  const [student, setStudent] = useState<Fee>({} as Fee);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getCookie('token');
        if (!token) {
          dispatch(setAuth(false));
          return;
        }

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const { data } = await axios.get('https://api.bayaanedu.com/users/user');
        setUserInfo({ firstName: data.first_name, lastName: data.last_name });

        dispatch(setAuth(true));
      } catch (e) {
        dispatch(setAuth(false));
      }
    };

    fetchData();
  }, [dispatch]);

  const getCookie = (name: string) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  };

  useEffect(() => {
    getSingleStudent();
  }, []);

  const getSingleStudent = async () => {
    try {
      const { data } = await axios.get<Fee>(`https://api.bayaanedu.com/fees/fees/${id}/`);
      setStudent(data);
    } catch (error) {
      console.error('Error fetching product:', error);
    }
  }

  const downloadInvoice = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text("Mustaqbal university", 105, 10, { align: 'center' });
    doc.text("Understanding and mastery", 105, 16, { align: 'center' });
    doc.setFontSize(10);
    doc.text("8th Street", 105, 22, { align: 'center' });
    doc.text("Call: ", 105, 34, { align: 'center' });
    doc.text("Website: www.mustaqbaluni.com", 105, 40, { align: 'center' });
    doc.setFontSize(12);
    doc.text(`Receipt No: ${student.receipt_number}`, 14, 50);
    doc.text(`Date: ${student.date_paid}`, 150, 50);

    doc.setFontSize(10);
    doc.text("Received from", 14, 60);
    doc.text(student.student_name, 50, 60);

    doc.text("The sum in Shillings", 14, 70);
    doc.text(`ksh ${student.amount_paid}`, 50, 70);

    doc.text("Class", 14, 80);
    doc.text(student.enrolled_class, 50, 80);

    doc.text("Mode of Payment", 14, 90);
    doc.text("[ ] Cash  [ ] Mpesa  [ ] Cheque", 50, 90);

    doc.text("Balance", 14, 100);
    doc.text(`ksh ${student.balance}`, 50, 100);

    doc.save(`Receipt_${student.receipt_number}.pdf`);
  };

  return (
    <>
      <div className="container mt-5 receipt">
        {auth ? (
          <div className="row justify-content-center">
            <div className="col-md-7">
              <div className="card" style={{backgroundColor:"white"}}>
                <div className="card-header text-center">
                  <div className="d-flex justify-content-between align-items-center">
                    <img src='/images/mustaqbal_university-removebg-preview.png' alt="Logo" style={{ width: '50px' }} />
                    <div>
                      <h5>Mustaqbal University</h5>
                      <p style={{ marginBottom: '0' }}>Understanding and mastery</p>
                    </div>
                    <div className="cards" style={{background:"none"}}>
                      <small>
                       8th sreet <br />
                        Po-Box 782-70100
                        Call: <br />
                        Website: www.mustaqbaluni.com
                      </small>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-6">
                      <strong>No.</strong>
                      <p className="border-bottom" style={{ marginBottom: '0' ,color:"red"}}>{student.receipt_number}</p>
                    </div>
                    <div className="col-6 text-end">
                      <strong>Date</strong>
                      <p className="border-bottom" style={{ marginBottom: '0' }}>{student.date_paid}</p>
                    </div>
                  </div>
                  <div className="mb-3">
                    <strong>Student Name</strong>
                    <p className="border-bottom" style={{ marginBottom: '0' }}>{student.student_name}</p>
                  </div>
                  <div className="mb-3">
                    <strong>Amount Paid</strong>
                    <p className="border-bottom" style={{ marginBottom: '0' }}>ksh {student.amount_paid}</p>
                  </div>
                  <div className="mb-3">
                    <strong>Section</strong>
                    <p className="border-bottom" style={{ marginBottom: '0' }}>{student.enrolled_class}</p>
                  </div>
                  <div className="mb-3">
                    <strong>Mode of Payment</strong>
                    <div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="cash" value="Cash"/>
                        <label className="form-check-label" htmlFor="cash">Cash</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="mpesa" value="Mpesa"/>
                        <label className="form-check-label" htmlFor="mpesa">Mpesa</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="cheque" value="Cheque"/>
                        <label className="form-check-label" htmlFor="cheque">Cheque</label>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <strong>Balance.</strong>
                    <p className="border-bottom" style={{ marginBottom: '0' }}>ksh {student.balance}</p>
                  </div>
                </div>
              </div>
              <div className="text-center mt-3">
                <button className="btn btn-primary" onClick={downloadInvoice}>Download Invoice</button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h3>You are not authenticated</h3>
          </div>
        )}
      </div>
    </>
  );
}
