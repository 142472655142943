import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

interface Student {
    name: string;
    index_number: string;
    guardian_number: string;
    number: string;
    branch: number;
    level: string;
    dob: string;
    address: string;
    health_status: string;
    fee: string;
    file_upload: File | null;
    citizen: string;
}

const UpdateStudent: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Get student id from URL params
    const navigate = useNavigate(); // Hook to handle navigation

    const [student, setStudent] = useState<Student | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchStudentDetails = async () => {
            try {
                const response = await axios.get<Student>(`https://api.bayaanedu.com/Branches/students/${id}`);
                setStudent(response.data);
            } catch (err) {
                console.error('Error fetching student details:', err);
                setError('Failed to fetch student details.');
            } finally {
                setLoading(false);
            }
        };

        fetchStudentDetails();
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (student) {
            setStudent({
                ...student,
                [name]: value,
            });
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (student) {
            setStudent({
                ...student,
                file_upload: file,
            });
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!student) return;
        setLoading(true);
        try {
            // Create a FormData object to handle file upload
            const formData = new FormData();
            Object.keys(student).forEach(key => {
                const value = student[key as keyof Student];
                if (value instanceof File) {
                    formData.append(key, value);
                } else {
                    formData.append(key, String(value));
                }
            });

            await axios.put(`https://api.bayaanedu.com/Branches/students/${id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setLoading(false);
            alert('Student updated successfully.');
            // Redirect to student details page after successful update
            navigate(`/students/${id}`);
        } catch (err) {
            setLoading(false);
            console.error('Error updating student:', err);
            if (axios.isAxiosError(err)) {
                console.error('Error response:', err.response?.data);
                setError(err.response?.data?.detail || 'Failed to update student.');
            } else {
                setError('Failed to update student.');
            }
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>Error: {error}</p>;
    }

    if (!student) {
        return <p>Student not found.</p>;
    }

    return (
        <div style={{ marginLeft: '230px', padding: '20px' }}>
            <h2>Update Student</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={student.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="index_number">Index Number:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="index_number"
                        name="index_number"
                        value={student.index_number}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="guardian_number">Guardian Number:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="guardian_number"
                        name="guardian_number"
                        value={student.guardian_number}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="number">Number:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="number"
                        name="number"
                        value={student.number}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="branch">Branch:</label>
                    <input
                        type="number"
                        className="form-control"
                        id="branch"
                        name="branch"
                        value={student.branch}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="level">Level:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="level"
                        name="level"
                        value={student.level}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="dob">Date of Birth:</label>
                    <input
                        type="date"
                        className="form-control"
                        id="dob"
                        name="dob"
                        value={student.dob}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="address">Address:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        value={student.address}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="health_status">Health Status:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="health_status"
                        name="health_status"
                        value={student.health_status}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="fee">Fee:</label>
                    <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        id="fee"
                        name="fee"
                        value={student.fee}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="file_upload">File Upload:</label>
                    <input
                        type="file"
                        className="form-control"
                        id="file_upload"
                        name="file_upload"
                        onChange={handleFileChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="citizen">Citizen:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="citizen"
                        name="citizen"
                        value={student.citizen}
                        onChange={handleChange}
                        required
                    />
                </div>
                {error && <p style={{ color: 'red' }}>Error: {error}</p>}
                <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Updating...' : 'Update Student'}
                </button>
            </form>
        </div>
    );
};

export default UpdateStudent;
