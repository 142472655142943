import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setAuth } from '../Redux/authSlice';

const TeachersComponent: React.FC = () => {
    const [teachers, setTeachers] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [userRole, setUserRole] = useState<string | null>(null);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<number | null>(null); // State to track the teacher ID being edited
    const [editedTeacherData, setEditedTeacherData] = useState<any>({}); // State to store edited teacher data temporarily
    const [fileUpload, setFileUpload] = useState<File | null>(null); // State to manage file upload
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getCookie('token');
                if (!token) {
                    console.error('No token found in cookies');
                    dispatch(setAuth(false));
                    setAuthenticated(false);
                    setError('No token found. Please log in.');
                    return;
                }
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                const userResponse = await axios.get('https://api.bayaanedu.com/users/user');
                setUserRole(userResponse.data.user.role);
                setAuthenticated(true);
                dispatch(setAuth(true));

                const teachersResponse = await axios.get('https://api.bayaanedu.com/Branches/teachers/');
                setTeachers(teachersResponse.data);
            } catch (err) {
                console.error('Error fetching data:', err);
                dispatch(setAuth(false));
                setAuthenticated(false);
                if (axios.isAxiosError(err)) {
                    if (err.response) {
                        setError(`Error: ${err.response.status} ${err.response.statusText}`);
                    } else if (err.request) {
                        setError('Error: No response received from the server');
                    } else {
                        setError(`Error: ${err.message}`);
                    }
                } else {
                    setError('An unexpected error occurred');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dispatch]);

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    const handleEdit = (teacherId: number) => {
        setEditMode(teacherId);
        // Find the teacher object being edited and initialize editedTeacherData state
        const teacherToEdit = teachers.find((teacher) => teacher.id === teacherId);
        if (teacherToEdit) {
            setEditedTeacherData({ ...teacherToEdit }); // Initialize with current data
        }
    };

    const handleSave = async () => {
        try {
            const token = getCookie('token');
            if (!token) {
                throw new Error('No token found in cookies');
            }
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            // Construct payload with only modified fields
            const payload: any = {};
            if (editedTeacherData.name) {
                payload.name = editedTeacherData.name;
            }
            if (editedTeacherData.email) {
                payload.email = editedTeacherData.email;
            }
            if (editedTeacherData.phone_number) {
                payload.phone_number = editedTeacherData.phone_number;
            }
            if (editedTeacherData.salary) {
                payload.salary = editedTeacherData.salary;
            }
            if (editedTeacherData.IdNumber) {
                payload.IdNumber = editedTeacherData.IdNumber;
            }
            if (fileUpload) {
                payload.file_upload = fileUpload; // Add file to payload if it's selected
            }

            const updateResponse = await axios.put(`https://api.bayaanedu.com/Branches/teachers/${editMode}/`, payload);
            console.log('Update response:', updateResponse.data);

            // Update teachers state to reflect changes
            const updatedTeachers = teachers.map((teacher) => {
                if (teacher.id === editMode) {
                    return { ...teacher, ...payload };
                }
                return teacher;
            });
            setTeachers(updatedTeachers);

            setEditMode(null); // Exit edit mode
            setEditedTeacherData({}); // Clear edited data
            setFileUpload(null); // Clear file upload state

            alert('Teacher updated successfully!');
        } catch (err) {
            console.error('Error updating teacher:', err);
            if (axios.isAxiosError(err)) {
                if (err.response) {
                    setError(`Error: ${err.response.status} ${err.response.statusText}`);
                } else if (err.request) {
                    setError('Error: No response received from the server');
                } else {
                    setError(`Error: ${err.message}`);
                }
            } else {
                setError('An unexpected error occurred');
            }
        }
    };

    const handleCancel = () => {
        setEditMode(null); // Exit edit mode
        setEditedTeacherData({}); // Clear edited data
        setFileUpload(null); // Clear file upload state
    };

    const handleDelete = async (teacherId: number) => {
        try {
            const token = getCookie('token');
            if (!token) {
                throw new Error('No token found in cookies');
            }
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            await axios.delete(`https://api.bayaanedu.com/Branches/teachers/${teacherId}/`);

            // Remove deleted teacher from the state
            const updatedTeachers = teachers.filter((teacher) => teacher.id !== teacherId);
            setTeachers(updatedTeachers);

            alert('Teacher deleted successfully!');
        } catch (err) {
            console.error('Error deleting teacher:', err);
            if (axios.isAxiosError(err)) {
                if (err.response) {
                    setError(`Error: ${err.response.status} ${err.response.statusText}`);
                } else if (err.request) {
                    setError('Error: No response received from the server');
                } else {
                    setError(`Error: ${err.message}`);
                }
            } else {
                setError('An unexpected error occurred');
            }
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            setFileUpload(files[0]);
        }
    };

    return (
        <div className="main-content">
            {loading ? (
                <div className="text-center mt-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : error ? (
                <div className="alert alert-danger mt-3">{error}</div>
            ) : (
                <div className="table-responsive mt-3">
                    <table className="table table-striped table-hover table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Salary</th>
                                <th>ID Number</th>
                                <th>File Upload</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {teachers.length > 0 ? (
                                teachers.map((teacher) => (
                                    <tr key={teacher.id}>
                                        <td>{editMode === teacher.id ? (
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={editedTeacherData.name || ''}
                                                onChange={(e) => setEditedTeacherData({ ...editedTeacherData, name: e.target.value })}
                                            />
                                        ) : (
                                            teacher.name
                                        )}</td>
                                        <td>{editMode === teacher.id ? (
                                            <input
                                                type="email"
                                                className="form-control"
                                                value={editedTeacherData.email || ''}
                                                onChange={(e) => setEditedTeacherData({ ...editedTeacherData, email: e.target.value })}
                                            />
                                        ) : (
                                            teacher.email
                                        )}</td>
                                        <td>{editMode === teacher.id ? (
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={editedTeacherData.phone_number || ''}
                                                onChange={(e) => setEditedTeacherData({ ...editedTeacherData, phone_number: e.target.value })}
                                            />
                                        ) : (
                                            teacher.phone_number
                                        )}</td>
                                        <td>{editMode === teacher.id ? (
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={editedTeacherData.salary || ''}
                                                onChange={(e) => setEditedTeacherData({ ...editedTeacherData, salary: e.target.value })}
                                            />
                                        ) : (
                                            teacher.salary
                                        )}</td>
                                        <td>{editMode === teacher.id ? (
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={editedTeacherData.IdNumber || ''}
                                                onChange={(e) => setEditedTeacherData({ ...editedTeacherData, IdNumber: e.target.value })}
                                            />
                                        ) : (
                                            teacher.IdNumber
                                        )}</td>
                                        <td>{editMode === teacher.id ? (
                                            <input
                                                type="file"
                                                className="form-control-file"
                                                onChange={handleFileChange}
                                            />
                                        ) : (
                                            teacher.file_upload ? (
                                                <a href={teacher.file_upload} target="_blank" rel="noopener noreferrer">Download File</a>
                                            ) : 'No File'
                                        )}</td>
                                        <td>
                                            {editMode === teacher.id ? (
                                                <>
                                                    <button className="btn btn-success btn-sm mr-2" onClick={handleSave}>Save</button>
                                                    <button className="btn btn-secondary btn-sm" onClick={handleCancel}>Cancel</button>
                                                </>
                                            ) : (
                                                <>
                                                    <button className="btn btn-primary btn-sm mr-2" onClick={() => handleEdit(teacher.id)}>Edit</button>
                                                    {userRole !== 'accountant' && (
                                                        <button className="btn btn-danger btn-sm" onClick={() => handleDelete(teacher.id)}>Delete</button>
                                                    )}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7} className="text-center">No teachers found.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default TeachersComponent;
