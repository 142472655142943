import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { setAuth } from './Redux/authSlice';
import { RootState } from './Redux/store';
import Charts from './Charts';

const Dashboard = () => {
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState<{ firstName: string; lastName: string; role: string }>({ firstName: '', lastName: '', role: '' });
    const [userRole, setUserRole] = useState('');
    const auth = useSelector((state: RootState) => state.auth.value);
    const [numStudents, setNumStudents] = useState(0);
    const [numTeachers, setNumTeachers] = useState(0);
    const [feesCount, setFeesCount] = useState(0);
    const [feesNotpaid, setNotpaid] = useState(0);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [incomeData, setIncomeData] = useState<{ [key: string]: number }>({});
    const [totalIncome, setTotalIncome] = useState<string>('');
    const [totalExpenses, setTotalExpenses] = useState<string>('');
    const[totalSalary, setTotalSalary] = useState<string>('')
    const [netIncome, setNetIncome] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [branchName, setBranchName] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // Set Authorization header
                const response = await axios.get('https://api.bayaanedu.com/fees/income/');
                setIncomeData(response.data.income_and_expenses_by_month);

                // Ensure the current month data exists before setting state
                const currentMonth = currentDateTime.toLocaleString('default', { month: 'long' });
                if (response.data.income_and_expenses_by_month && response.data.income_and_expenses_by_month[currentMonth]) {
                    const currentMonthData = response.data.income_and_expenses_by_month[currentMonth];
                    setTotalIncome(currentMonthData.total_income);
                    setTotalExpenses(currentMonthData.total_expenses);
                    setNetIncome(currentMonthData.net_income);
                    setTotalSalary(currentMonthData.total_salaries);
                } else {
                    console.log(`No data found for ${currentMonth}`);
                }
            } catch (error) {
                console.error('Error fetching income data:', error);
            }
        };

        fetchData();
    }, [currentDateTime]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                const { data } = await axios.get('https://api.bayaanedu.com/users/user');
                setUserInfo({ firstName: data.first_name, lastName: data.last_name, role: data.role });
                setUserRole(data.user.role); // Set user role state
                setBranchName(data.user.branch_name); // Set branch name state
                console.log('User role:', data.user.role); // Log user role to console
                console.log('Branch Name:', data.user.branch_name); // Log branch name to console
                dispatch(setAuth(true));
            } catch (e) {
                console.error('Error fetching user data:', e);
                dispatch(setAuth(false));
                setError('Failed to fetch user data');
            }
        };

        fetchData();
    }, [dispatch]);

    useEffect(() => {
        const fetchFeesCount = async () => {
            try {
                const response = await axios.get('https://api.bayaanedu.com/fees/fees-count/');
                setFeesCount(response.data.num_fees_paid);
            } catch (error) {
                console.error('Error fetching fees count:', error);
            }
        };
        const fetchFeesNotPaidCount = async () => {
            try {
                const response = await axios.get('https://api.bayaanedu.com/fees/fees_not_paid_count/');
                setNotpaid(response.data.num_students_not_paid);
            } catch (error) {
                console.error('Error fetching fees count:', error);
            }
        };

        const fetchStudentCount = async () => {
            try {
                const response = await axios.get('https://api.bayaanedu.com/Branches/students-count');
                setNumStudents(response.data.num_students);
            } catch (error) {
                console.error('Error fetching student count:', error);
            }
        };

        const fetchTeacherCount = async () => {
            try {
                const response = await axios.get('https://api.bayaanedu.com/Branches/teacher_count');
                setNumTeachers(response.data.num_teachers);
            } catch (error) {
                console.error('Error fetching teacher count:', error);
            }
        };
        fetchFeesCount();
        fetchFeesNotPaidCount();
        fetchStudentCount();
        fetchTeacherCount();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const currentMonth = currentDateTime.toLocaleString('default', { month: 'long' });
    const currentDay = currentDateTime.getDate();

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    const cardStyle = {
        backgroundColor: branchName === 'Mustaqbal university' ? '#238741' : branchName === '#238741' ? 'orange' : '#238741',
        color: '#ffffff',
        border: '1px solid #fff',
        width:"300px",
        height:"150px",
        marginTop:"20px",
        boxShadow: '0px 0px 10px rgba(0, 0, 10, 0.2)', // Adding box shadow
    };
    const buttonStyle = {
        backgroundColor: '',
        color: 'white'
    };
    const getLogoImage = () => {
        switch (branchName) {
            case 'Mustaqbal university':
                return '/images/mustaqbal_university-removebg-preview.png';
            case 'Bayan':
                return '/images/bayaan_-removebg-preview.png';
            default:
                return null;
        }
    };
    return (
        <div className="container-fluid p-0">
            {loading ? (
                <p>Loading...</p>
            ) : auth ? (
                <div>

                    <div className="dashboard-container">
                    <div className="upper-Hero" style={{ backgroundColor: '#238741', color: '#ffff' }}>
                    <div>
                                {branchName && (
                                <img
                                    src={getLogoImage() || ''}
                                    alt={`${branchName} Logo`}
                                    className="logoIcon"
                                    style={{ width: '40px' }}
                                />
                            )}

                                </div>
                                <div className="left-hero">
                                    <h4 style={{ paddingLeft: "10px" }}>Dashboard</h4>
                                </div>
                                <div className="right-hero">
                                    <button className='btn btn-primary' style={buttonStyle}>
                                        Today: {currentMonth} {currentDay}
                                    </button>
                                </div>
                              
                            </div>
                        <div className="middle-hero">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card text-center" style={cardStyle}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-user-graduate" style={{ fontSize: '35px', color: '#fff' }}></i>
                                            <h2 className="students-count">{numStudents}</h2>
                                            <p className="card-text">Number of students</p>
                                            <button className="btn btn-primary"><Link to="/students" className="navLink" style={{ color: "white", textDecoration: "none", }}>View</Link></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card text-center" style={cardStyle}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-chalkboard-teacher" style={{ fontSize: '35px', color: '#fff' }}></i>
                                            <h2 className="teachers-count">{numTeachers}</h2>
                                            <p className="card-text">Number of teachers</p>
                                            <button className="btn btn-primary"><Link to="/teachers" className="navLink" style={{ color: "white", textDecoration: "none" }}>View</Link></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card text-center" style={cardStyle}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-money-bill" style={{ fontSize: '35px', color: '#fff' }}></i>
                                            <h2 className="fees-count">{feesCount}</h2>
                                            <p className="card-text">Number of students paid</p>
                                            <button className="btn btn-primary"><Link to="/fees" className="navLink" style={{ color: "white", textDecoration: "none" }}>View</Link></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card text-center" style={cardStyle}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-money-bill" style={{ fontSize: '35px', color: '#fff' }}></i>
                                            <h2 className="fees-count">{feesNotpaid}</h2>
                                            <p className="card-text">Number of students Not paid</p>
                                            <button className="btn btn-primary"><Link to="/fees_not_paid" className="navLink" style={{ color: "white", textDecoration: "none" }}>View</Link></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card text-center" style={cardStyle}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-money-bill" style={{ fontSize: '35px', color: '#fff' }}></i>
                                            <h2 className="fees-count">{totalIncome} ksh</h2>
                                            <p className="card-text">Total income</p>
                                            <button className="btn btn-primary"><Link to="/income" className="navLink" style={{ color: "white", textDecoration: "none", }}>View</Link></button>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card text-center" style={cardStyle}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-money-bill" style={{ fontSize: '35px', color: '#fff' }}></i>
                                            <h2 className="fees-count">{totalExpenses} ksh</h2>
                                            <p className="card-text">Total expenses</p>
                                            <button className="btn btn-primary"><Link to="/ExpenseList" className="navLink" style={{ color: "white", textDecoration: "none", }}>View</Link></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card text-center" style={cardStyle}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-wallet" style={{ fontSize: '35px', color: '#fff' }}></i>
                                            <h2 className="fees-count">{totalSalary} ksh</h2>
                                            <p className="card-text">Total Salaries</p>
                                            <button className="btn btn-primary"><Link to="/income" className="navLink" style={{ color: "white", textDecoration: "none", }}>View</Link></button>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card text-center" style={cardStyle}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-money-bill" style={{ fontSize: '35px', color: '#fff' }}></i>
                                            <h2 className="fees-count">{netIncome} ksh</h2>
                                            <p className="card-text">Net income</p>
                                            <button className="btn btn-primary"><Link to="/income" className="navLink" style={{ color: "white", textDecoration: "none", }}>View</Link></button>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <Charts/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <p>{error}</p>
            )}
        </div>
    );
};

export default Dashboard;
