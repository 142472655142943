import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux'; // Assuming you use Redux for state management
import 'bootstrap/dist/css/bootstrap.min.css';
import { setAuth } from '../Redux/authSlice'; // Adjust path as per your project structure

interface Student {
  id: number;
  name: string;
  class_associated: string;
  guardian_number: string;
  number: string;
}

const FeesNotPaid: React.FC = () => {
  const [students, setStudents] = useState<Student[]>([]);
  const [month, setMonth] = useState<string>((new Date().getMonth() + 1).toString());
  const [year] = useState<number>(new Date().getFullYear());
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, [month]); // Fetch data whenever month changes

  const getCookie = (name: string) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  };

  const fetchData = async () => {
    try {
      const token = getCookie('token');
      if (!token) {
        dispatch(setAuth(false));
        return;
      }

      setLoading(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`https://api.bayaanedu.com/fees/fees_not_paid/?month=${month}&year=${year}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('Fetched students who have not paid:', response.data);
      setStudents(response.data || []); // Assuming response.data directly contains the array of students
      setLoading(false);
      dispatch(setAuth(true)); // Assuming you update auth state upon successful fetch
    } catch (error) {
      console.error('Error fetching students:', error);
      setLoading(false);
    }
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setMonth(event.target.value);
  };

  return (
    <div className="container mt-2">
      <h6 className="mb-2" style={{marginLeft:"180px"}}>Students Who Have Not Paid Fees</h6>
      <div className="form-group" style={{marginLeft:"180px"}}>
        <label htmlFor="monthSelect">Select Month</label>
        <select
          id="monthSelect"
          className="form-control"
          value={month}
          onChange={handleMonthChange}
        >
          {Array.from({ length: 12 }, (_, i) => i + 1).map(m => (
            <option key={m} value={m}>
              {new Date(0, m - 1).toLocaleString('default', { month: 'long' })}
            </option>
          ))}
        </select>
      </div>
      {loading ? (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : error ? (
        <div className="alert alert-danger mt-4" role="alert">
          {error}
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-striped mt-2">
            <thead style={{ backgroundColor: '#f0f0f0' }}>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Class</th>
                <th>Guardian Number</th>
                <th>Contact Number</th>
              </tr>
            </thead>
            <tbody>
              {students.length === 0 ? (
                <tr>
                  <td colSpan={5}>No students found for the selected month.</td>
                </tr>
              ) : (
                students.map(student => (
                  <tr key={student.id}>
                    <td>{student.id}</td>
                    <td>{student.name}</td>
                    <td>{student.class_associated}</td>
                    <td>{student.guardian_number}</td>
                    <td>{student.number}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default FeesNotPaid;
