import React, { useState } from 'react';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

interface Teacher {
    name: string;
    email: string;
    phone_number: string;
    date_reg: string;
    salary: string;
    IdNumber: string;
    file_upload: File | null;
}
const TeachersComponent: React.FC = () => {
    const [teacher, setTeacher] = useState<Teacher>({
        name: '',
        email: '',
        phone_number: '',
        date_reg: '',
        salary: '',
        IdNumber: '',
        file_upload: null,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [added, setAdded] = useState<boolean>(false);
    const navigate = useNavigate(); // Hook to handle navigation

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setTeacher({
            ...teacher,
            [name]: value,
        });
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        setTeacher({
            ...teacher,
            file_upload: file,
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();  
        setLoading(true);
        try {
            // Create a FormData object to handle file upload
            const formData = new FormData();
            Object.keys(teacher).forEach(key => {
                const value = teacher[key as keyof Teacher];
                if (value instanceof File) {
                    formData.append(key, value);
                } else {
                    formData.append(key, String(value));
                }
            });

            const apiUrl = 'https://api.bayaanedu.com/Branches/teachers/';
            const response = await axios.post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Add teacher response:', response.data);
            setLoading(false);
            setAdded(true);
            alert('Teacher added successfully.');
            // Redirect to teachers list page or any other relevant page after successful addition
            navigate('/teachers');
        } catch (err) {
            setLoading(false);
            console.error('Error adding teacher:', err);
            if (axios.isAxiosError(err)) {
                console.error('Error response:', err.response?.data);
                setError(err.response?.data?.detail || 'Failed to add teacher.');
            } else {
                setError('Failed to add teacher.');
            }
        }
    };

    if (added) {
        // Redirect if teacher added successfully
        return <Navigate to="/teachers" />;
    }

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                    <h2 className="mb-4 text-center">Add Teacher</h2>
                    <form onSubmit={handleSubmit} className="add-teacher-form">
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={teacher.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email:</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                value={teacher.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phone_number" className="form-label">Phone Number:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="phone_number"
                                name="phone_number"
                                value={teacher.phone_number}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="date_reg" className="form-label">Date of Registration:</label>
                            <input
                                type="date"
                                className="form-control"
                                id="date_reg"
                                name="date_reg"
                                value={teacher.date_reg}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="salary" className="form-label">Salary:</label>
                            <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                id="salary"
                                name="salary"
                                value={teacher.salary}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="IdNumber" className="form-label">ID Number:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="IdNumber"
                                name="IdNumber"
                                value={teacher.IdNumber}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="file_upload" className="form-label">File Upload:</label>
                            <input
                                type="file"
                                className="form-control"
                                id="file_upload"
                                name="file_upload"
                                onChange={handleFileChange}
                                required
                            />
                        </div>
                        {error && <p className="text-danger">Error: {error}</p>}
                        <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                            {loading ? 'Adding...' : 'Add Teacher'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default TeachersComponent;
