import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface Salary {
  id: number;
  teacher: number;
  teacher_name: string;
  month: string;
  amount: string;
  date_paid: string;
  branch: number;
}

const Salaries: React.FC = () => {
  const [salaries, setSalaries] = useState<Salary[]>([]);
  const [isEditing, setIsEditing] = useState<number | null>(null);
  const [editableSalary, setEditableSalary] = useState<Salary | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.bayaanedu.com/fees/salaries/');
        setSalaries(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching salary data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = async (salary: Salary) => {
    if (isEditing !== null && editableSalary) {
      try {
        const response = await axios.patch(`https://api.bayaanedu.com/fees/salaries/${editableSalary.id}/`, editableSalary, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('Server response:', response.data);
        setSalaries(salaries.map(s => (s.id === editableSalary.id ? { ...editableSalary, ...response.data } : s)));
      } catch (error: any) {
        console.error('Error updating salary:', error.response ? error.response.data : error.message);
      }
      setIsEditing(null);
      setEditableSalary(null);
    } else {
      setIsEditing(salary.id);
      setEditableSalary(salary);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (editableSalary) {
      setEditableSalary({ ...editableSalary, [name]: value });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mt-3">
      <h2 style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Salary Details</h2>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Teacher Name</th>
              <th>Month</th>
              <th>Amount</th>
              <th>Date Paid</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {salaries.map(salary => (
              <tr key={salary.id}>
                <td>{salary.id}</td>
                <td>
                  {isEditing === salary.id ? (
                    <input
                      type="text"
                      name="teacher_name"
                      value={editableSalary?.teacher_name || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    salary.teacher_name
                  )}
                </td>
                <td>
                  {isEditing === salary.id ? (
                    <input
                      type="text"
                      name="month"
                      value={editableSalary?.month || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    salary.month
                  )}
                </td>
                <td>
                  {isEditing === salary.id ? (
                    <input
                      type="text"
                      name="amount"
                      value={editableSalary?.amount || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    `${salary.amount} ksh`
                  )}
                </td>
                <td>
                  {isEditing === salary.id ? (
                    <input
                      type="text"
                      name="date_paid"
                      value={editableSalary?.date_paid || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    salary.date_paid
                  )}
                </td>
                <td>
                  <button className="btn btn-primary" onClick={() => handleEditClick(salary)}>
                    {isEditing === salary.id ? 'Save' : 'Edit'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Salaries;
