import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

interface ErrorResponse {
  detail: string; // Adjust the structure according to the actual error response
}

interface Branch {
  id: number;
  name: string;
  address: string;
  contact_number: string;
  manager: string;
}

interface BranchFormProps {
  branch?: Branch;
  onSuccess?: () => void;
}

const BranchForm: React.FC<BranchFormProps> = ({ branch, onSuccess }) => {
  const [name, setName] = useState(branch?.name || '');
  const [address, setAddress] = useState(branch?.address || '');
  const [contactNumber, setContactNumber] = useState(branch?.contact_number || '');
  const [manager, setManager] = useState(branch?.manager || '');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (branch) {
        // Update existing branch
        await axios.put(`https://api.bayaanedu.com/Branches/branches/${branch.id}/`, {
          name,
          address,
          contact_number: contactNumber,
          manager,
        });
        setSuccess('Branch updated successfully');
      } else {
        // Create new branch
        await axios.post('https://api.bayaanedu.com/Branches/branches/', {
          name,
          address,
          contact_number: contactNumber,
          manager,
        });
        setSuccess('Branch created successfully');
      }
      setError(null);
      if (onSuccess) onSuccess();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<ErrorResponse>;
        setError(axiosError.response?.data.detail || 'Unknown error');
      } else {
        setError('Unknown error');
      }
      setSuccess(null);
    }
  };

  return (
    <div className="container-fluid mt-5">
      <div className="row">
        <div className="col-md-3" style={{ paddingLeft: '240px' }}>
        </div>
        <div className="col-md-9">
          {/* Main content */}
          <div className="container mt-3">
            <h2>{branch ? 'Edit Branch' : 'Create Branch'}</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Name:</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Address:</label>
                <input
                  type="text"
                  className="form-control"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Contact Number:</label>
                <input
                  type="text"
                  className="form-control"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Manager:</label>
                <input
                  type="text"
                  className="form-control"
                  value={manager}
                  onChange={(e) => setManager(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary">
                {branch ? 'Update' : 'Create'}
              </button>
            </form>
            {error && <div className="alert alert-danger mt-3">Error: {error}</div>}
            {success && <div className="alert alert-success mt-3">Success: {success}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchForm;
