import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../Redux/store';
import { setAuth } from '../Redux/authSlice';

const IncomeExpenseSummary: React.FC = () => {
    const [incomeData, setIncomeData] = useState<{ [key: string]: { total_income: string; total_expenses: string; total_salaries: string; net_income: string } }>({});
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', role: '' });
    const auth = useSelector((state: RootState) => state.auth.value);

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    const fetchUserData = async () => {
        try {
            const token = getCookie('token');
            if (!token) {
                dispatch(setAuth(false));
                return;
            }
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const { data } = await axios.get('https://api.bayaanedu.com/users/user');
            setUserInfo({ firstName: data.first_name, lastName: data.last_name, role: data.role });
            console.log('User Info:', data);
            dispatch(setAuth(true));
        } catch (e) {
            console.error('Error fetching user data:', e);
            dispatch(setAuth(false));
        }
    };

    const fetchIncomeData = async () => {
        try {
            const response = await axios.get('https://api.bayaanedu.com/fees/income/');
            setIncomeData(response.data.income_and_expenses_by_month);
            console.log('Income Data:', response.data.income_and_expenses_by_month);
        } catch (error) {
            console.error('Error fetching income data:', error);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [dispatch]);

    useEffect(() => {
        if (auth) {
            fetchIncomeData();
        }
    }, [auth]);

    return (
        <>
            <div className="container mt-4">
                {auth ? (
                    <div>
                        <h3 style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Income and Expense Summary</h3>
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover income-expense-table" style={{ width: '730px', margin: '10px auto' }}>
                                <thead>
                                    <tr>
                                        <th>Month</th>
                                        <th>Total Income</th>
                                        <th>Total Expenses</th>
                                        <th>Total Salaries</th>
                                        <th>Net Income</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(incomeData).map((month, index) => (
                                        <tr key={month} className={index % 2 === 0 ? 'even-row' : ''}>
                                            <td>{month}</td>
                                            <td>ksh {incomeData[month].total_income}</td>
                                            <td>ksh {incomeData[month].total_expenses}</td>
                                            <td>ksh {incomeData[month].total_salaries}</td>
                                            <td>ksh {incomeData[month].net_income}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h1>You are not authenticated or authorized to view this page</h1>
                    </div>
                )}
            </div>
        </>
    );
};

export default IncomeExpenseSummary;
