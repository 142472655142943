import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RootState } from '../Redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../Redux/authSlice';
import { Navigate } from 'react-router-dom';

const AddClass: React.FC = () => {
    const [className, setClassName] = useState<string>('');
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [redirectToClasses, setRedirectToClasses] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                const { data } = await axios.get('https://api.bayaanedu.com/users/user');
                dispatch(setAuth(true));
            } catch (e) {
                dispatch(setAuth(false));
            }
        };

        fetchData();
    }, [dispatch]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const newClass = {
            name: className
        };

        try {
            const response = await axios.post('https://api.bayaanedu.com/Branches/classes/', newClass, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            console.log('New class created:', response.data);
            setShowAlert(true);
            setClassName('');
            setRedirectToClasses(true); // Set state to redirect after successful submission
        } catch (error) {
            console.error('Error creating class:', error);
        }
    };

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    if (redirectToClasses) {
        return <Navigate to='/classes' />; // Redirect to classes page after successful submission
    }

    return (
        <div className="container">
            <div className="row justify-content-center mt-5">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title text-center mb-4">Create New Class</h2>
                            {showAlert && (
                                <div className="alert alert-success" role="alert">
                                    Class added successfully!
                                </div>
                            )}
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="className" className="form-label">
                                        Class Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="className"
                                        value={className}
                                        onChange={(e) => setClassName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="d-grid">
                                    <button type="submit" className="btn btn-primary btn-block">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddClass;
