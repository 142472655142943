import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface Class {
  id: number;
  name: string;
}

const Classes: React.FC = () => {
  const [classes, setClasses] = useState<Class[]>([]);
  const [isEditing, setIsEditing] = useState<number | null>(null);
  const [editableClass, setEditableClass] = useState<Class | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.bayaanedu.com/Branches/classes/');
        setClasses(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching class data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = async (classItem: Class) => {
    if (isEditing !== null && editableClass) {
      try {
        const response = await axios.patch(`https://api.bayaanedu.com/Branches/classes/${editableClass.id}/`, editableClass, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('Server response:', response.data);
        setClasses(classes.map(c => (c.id === editableClass.id ? { ...editableClass, ...response.data } : c)));
      } catch (error: any) {
        console.error('Error updating class:', error.response ? error.response.data : error.message);
      }
      setIsEditing(null);
      setEditableClass(null);
    } else {
      setIsEditing(classItem.id);
      setEditableClass(classItem);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (editableClass) {
      setEditableClass({ ...editableClass, [name]: value });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mt-3">
      <h2 style={{textAlign:"center"}}>Class Details</h2>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Class Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {classes.map(classItem => (
              <tr key={classItem.id}>
                <td>{classItem.id}</td>
                <td>
                  {isEditing === classItem.id ? (
                    <input
                      type="text"
                      name="name"
                      value={editableClass?.name || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    classItem.name
                  )}
                </td>
                <td>
                  <button className="btn btn-primary" onClick={() => handleEditClick(classItem)}>
                    {isEditing === classItem.id ? 'Save' : 'Edit'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Classes;
