import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Redux/store';
import { setAuth } from '../Redux/authSlice';
import { Link } from 'react-router-dom';

interface Fee {
  id: number;
  student: string | number;
  student_name: string;
  month: string;
  total_amount: string;
  amount_paid: string;
  date_paid: string;
  class_associated: string;
  balance: string;
  receipt_number: number;
  collected_by: string;
}

const ShowFees: React.FC = () => {
  const [fees, setFees] = useState<Fee[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth.value);
  const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '' });

  const getCookie = (name: string) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  };

  const fetchData = async () => {
    try {
      const token = getCookie('token');
      if (!token) {
        dispatch(setAuth(false));
        return;
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const { data } = await axios.get('https://api.bayaanedu.com/users/user', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setUserInfo({ firstName: data.first_name, lastName: data.last_name });
      dispatch(setAuth(true));
    } catch (e) {
      dispatch(setAuth(false));
    }
  };

  const fetchFees = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://api.bayaanedu.com/fees/fees/?page=${page}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setFees(response.data.results);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.error('Error fetching fees:', error);
    }
    setLoading(false);
  };

  const handleDelete = async (id: number) => {
    if (window.confirm('Are you sure you want to delete this fee record?')) {
      try {
        await axios.delete(`https://api.bayaanedu.com/fees/fees/${id}/`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setFees(fees.filter(fee => fee.id !== id));
      } catch (error) {
        console.error('Error deleting fee record:', error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (auth) {
      fetchFees();
    }
  }, [auth, page]);

  const handleNextPage = () => {
    setPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage(prevPage => prevPage - 1);
  };

  const filteredFees = fees.filter(fee =>
    fee.student_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="container mt-5">
        {auth ? (
          <div className="table-responsive" >
            <div className="mb-3 text-center">
              <input
                type="text"
                className="form-control"
                placeholder="Search by student name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {loading ? (
              <div>Loading...</div>
            ) : (
              <table className="table table-striped table-bordered" style={{ width: '100%' }}>
                <thead className="table-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Month</th>
                    <th scope="col">Total Amount</th>
                    <th scope="col">Amount Paid</th>
                    <th scope="col">Date</th>
                    <th scope="col">class</th>
                    <th scope="col">Balance</th>
                    <th scope="col">Receipt Number</th>
                    <th scope="col">Collected By</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredFees.map((fee, index) => (
                    <tr key={fee.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{fee.student_name}</td>
                      <td>{fee.month}</td>
                      <td>ksh {fee.total_amount}</td>
                      <td>{fee.amount_paid}</td>
                      <td>{fee.date_paid}</td>
                      <td>{fee.class_associated}</td>
                      <td>ksh {fee.balance}</td>
                      <td>{fee.receipt_number}</td>
                      <td>{fee.collected_by}</td>
                      <td>
                        <Link className="btn btn-outline-primary btn-sm" to={`/${fee.id}/`}>
                          Detail
                        </Link>
                        <button
                          className="btn btn-outline-danger btn-sm ml-2"
                          onClick={() => handleDelete(fee.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <div className="d-flex justify-content-between">
              <button className="btn btn-primary" disabled={page === 1} onClick={handlePrevPage}>
                Previous
              </button>
              <button className="btn btn-primary" disabled={page >= totalPages} onClick={handleNextPage}>
                Next
              </button>
            </div>
          </div>
        ) : (
          <div>
            <h3>You are not authenticated</h3>
          </div>
        )}
      </div>
    </>
  );
};

export default ShowFees;
