import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './Redux/store';
import { setAuth } from './Redux/authSlice';

const Sidebar: React.FC = () => {
    const [userRole, setUserRole] = useState<string | null>(null);
    const [branchName, setBranchName] = useState<string | null>(null);
    const [showFeesOptions, setShowFeesOptions] = useState(false);
    const [showClassOptions, setShowClassOptions] = useState(false);
    const [showExamsOptions, setShowExamsOptions] = useState(false);
    const dispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.auth.value);
    const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth > 980);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                const response = await axios.get('https://api.bayaanedu.com/users/user');
                setUserRole(response.data.user.role);
                setBranchName(response.data.user.branch_name);
                dispatch(setAuth(true));
            } catch (error) {
                console.error('Error fetching user data:', error);
                dispatch(setAuth(false));
                setError('Failed to fetch user data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();

        const handleResize = () => {
            setIsSidebarVisible(window.innerWidth > 980);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);

    const toggleSidebarVisibility = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    const toggleFeesOptions = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        setShowFeesOptions(!showFeesOptions);
    };

    const toggleExamsOptions = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        setShowExamsOptions(!showExamsOptions);
    };

    const toggleClassOptions = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        setShowClassOptions(!showClassOptions);
    };

    const sidebarStyle = {
        backgroundColor: branchName === 'Mustaqbal university' ? '#238741' : branchName === 'Bayan' ? '#238741' : '#238741',
    };

    return (
        <>
            {auth ? (
                <>
                    <button
                        className="btn btn-primary toggle-sidebar-btn d-lg-none"
                        onClick={toggleSidebarVisibility}
                        style={{ position: 'fixed', top: '10px', left: '10px', zIndex: 1000 }}
                    >
                        <i className="fa-solid fa-bars"></i>
                    </button>
                    <div className={`sidebar-container ${isSidebarVisible ? 'visible' : ''}`} style={sidebarStyle}>
                        <div className="sidebar">
                            <hr className="separator" />
                            <ul className="nav nav-pills flex-column mb-auto">
                                <li>
                                    <Link to="/dashboard" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-desktop me-2" style={{ color: 'white' }}></i>
                                        Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/mustaqbal' className="nav-link" style={{ color: 'white' }}>
                                        <img src="/images/mustaqbal_university-removebg-preview.png" alt="Mustaqbal" className="me-2" style={{ width: '20px', height: '20px' }} />
                                        Mustaqbal
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/bayan' className="nav-link" style={{ color: 'white' }}>
                                        <img src="/images/bayaan_-removebg-preview.png" alt="Bayan" className="me-2" style={{ width: '20px', height: '20px' }} />
                                        Bayan
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/students" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-university me-2" style={{ color: 'white' }}></i>
                                        Students
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/add-students" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-building me-2" style={{ color: 'white' }}></i>
                                        Add Student
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/ExpenseList" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-money-bill me-2" style={{ color: 'white' }}></i>
                                        ExpenseList
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/expenses" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-add me-2" style={{ color: 'white' }}></i>
                                        Add Expense
                                    </Link>
                                </li>
                                <li>
                                    <a href="/" className="nav-link" style={{ color: 'white' }} onClick={toggleFeesOptions}>
                                        <i className="fa-solid fa-money-bill me-2" style={{ color: 'white' }}></i>
                                        Fees
                                        <i className={`fa-solid fa-circle-chevron-${showFeesOptions ? 'up' : 'down'} ms-2`} style={{ color: 'white' }}></i>
                                    </a>
                                    {showFeesOptions && (
                                        <ul className="nav flex-column ms-2">
                                            <li>
                                                <Link to="/fees" className="nav-link subNavItem" style={{ color: 'white' }}>
                                                    <i className="fa-solid fa-dollar-sign me-2" style={{ color: 'white' }}></i>
                                                    View Fees
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/add-fees" className="nav-link subNavItem" style={{ color: 'white' }}>
                                                    <i className="fa-solid fa-wallet me-2" style={{ color: 'white' }}></i>
                                                    Add Fee
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/fees_not_paid" className="nav-link subNavItem" style={{ color: 'white' }}>
                                                    <i className="fa-solid fa-sack-dollar me-2" style={{ color: 'white' }}></i>
                                                    Fees Not Paid
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                </li>
                                <li>
                                    <a href="/" className="nav-link" style={{ color: 'white' }} onClick={toggleExamsOptions}>
                                        <i className="fa-solid fa-graduation-cap me-2" style={{ color: 'white' }}></i>
                                        Exams
                                        <i className={`fa-solid fa-circle-chevron-${showExamsOptions ? 'up' : 'down'} ms-2`} style={{ color: 'white' }}></i>
                                    </a>
                                    {showExamsOptions && (
                                        <ul className="nav flex-column ms-2">
                                            <li>
                                                <Link to="/exams" className="nav-link subNavItem" style={{ color: 'white' }}>
                                                    <i className="fa-solid fa-list me-2" style={{ color: 'white' }}></i>
                                                    View Exams
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/add-exams" className="nav-link subNavItem" style={{ color: 'white' }}>
                                                    <i className="fa-solid fa-calendar-plus me-2" style={{ color: 'white' }}></i>
                                                    Create Exam
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/marks" className="nav-link subNavItem" style={{ color: 'white' }}>
                                                    <i className="fa-solid fa-marker me-2" style={{ color: 'white' }}></i>
                                                    Marking
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/add-marks" className="nav-link subNavItem" style={{ color: 'white' }}>
                                                    <i className="fa-solid fa-calendar-check me-2" style={{ color: 'white' }}></i>
                                                    create marks
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                </li>
                                <li>
                                    <a href="/" className="nav-link" style={{ color: 'white' }} onClick={toggleClassOptions}>
                                        <i className="fa-solid fa-school me-2" style={{ color: 'white' }}></i>
                                        Classes
                                        <i className={`fa-solid fa-circle-chevron-${showClassOptions ? 'up' : 'down'} ms-2`} style={{ color: 'white' }}></i>
                                    </a>
                                    {showClassOptions && (
                                        <ul className="nav flex-column ms-2" style={{backgroundColor:"white"}}>
                                            <li>
                                                <Link to="/classes" className="nav-link subNavItem" style={{ color: 'black' }}>
                                                    <i className="fa-solid fa-chalkboard me-2" style={{ color: 'black' }}></i>
                                                    View Classes
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/add-classes" className="nav-link subNavItem" style={{ color: 'black' }}>
                                                    <i className="fa-solid fa-chalkboard-teacher me-2" style={{ color: 'black' }}></i>
                                                    Add Class
                                                </Link>
                                            </li>
                                           
                                        </ul>
                                    )}
                                </li>
                                <li>
                                    <Link to="/teachers" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-chalkboard-user me-2" style={{ color: 'white' }}></i>
                                        Teachers
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/add-teachers" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-chalkboard-user me-2" style={{ color: 'white' }}></i>
                                       Add Teacher
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/salaries" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-user-tie me-2" style={{ color: 'white' }}></i>
                                        Salary
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/add-salary" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-plus me-2" style={{ color: 'white' }}></i>
                                        Create Salary
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/register" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-user-plus me-2" style={{ color: 'white' }}></i>
                                        Register User
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/users" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-users me-2" style={{ color: 'white' }}></i>
                                        all Users
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/Account" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-user me-2" style={{ color: 'white' }}></i>
                                        Account
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </>
            ) : (
                <div className="error">
                </div>
            )}
        </>
    );
};

export default Sidebar;
