import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const StudentDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Get student id from URL params
    const navigate = useNavigate(); // Hook to handle navigation

    const [student, setStudent] = useState<any>(null);
    const [userRole, setUserRole] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [editable, setEditable] = useState<boolean>(false);
    const [authenticated, setAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        const fetchStudentDetails = async () => {
            try {
                const response = await axios.get(`https://api.bayaanedu.com/Branches/students/${id}`);
                setStudent(response.data);
            } catch (err) {
                console.error('Error fetching student details:', err);
                setError('Failed to fetch student details.');
            } finally {
                setLoading(false);
            }
        };

        const fetchUserRole = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get('https://api.bayaanedu.com/users/user');
                setUserRole(data.user.role); // Ensure you access the role correctly from the response
                setAuthenticated(true); // Set authenticated to true after successful login
            } catch (error) {
                console.error('Failed to fetch user role:', error);
                setError('Failed to fetch user role');
            } finally {
                setLoading(false);
            }
        };

        fetchStudentDetails();
        fetchUserRole();
    }, [id]);

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this student?')) {
            try {
                await axios.delete(`https://api.bayaanedu.com/Branches/students/${id}`);
                navigate('/students');
            } catch (err) {
                console.error('Error deleting student:', err);
                setError('Failed to delete student.');
            }
        }
    };

    const handleEditToggle = () => {
        setEditable(!editable);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setStudent({
            ...student,
            [name]: value,
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        try {
            const updatedData = {
                name: student.name,
                index_number: student.index_number,
                number: student.number,
                guardian_number: student.guardian_number,
                level: student.level,
                dob: student.dob,
                address: student.address,
                health_status: student.health_status,
                fee: student.fee,
                citizen: student.citizen,
                // Exclude date_reg and id if not required by your backend
            };


            await axios.put(`https://api.bayaanedu.com/Branches/students/${id}/`, updatedData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setLoading(false);
            alert('Student updated successfully.');
            setEditable(false); // Exit edit mode
        } catch (err) {
            setLoading(false);
            console.error('Error updating student:', err);
            if (axios.isAxiosError(err)) {
                console.error('Error response:', err.response?.data);
                setError(err.response?.data?.detail || 'Failed to update student.');
            } else {
                setError('Failed to update student.');
            }
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>Error: {error}</p>;
    }

    if (!student) {
        return <p>Student not found.</p>;
    }

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title text-center mb-4">Student Details</h2>
                            {editable ? (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="name">Name:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            value={student.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="number">Number:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="number"
                                            name="number"
                                            value={student.number}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="guardian_number">Guardian Number:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="guardian_number"
                                            name="guardian_number"
                                            value={student.guardian_number}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="level">Level:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="level"
                                            name="level"
                                            value={student.level}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="dob">Date of Birth:</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="dob"
                                            name="dob"
                                            value={student.dob}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address">Address:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="address"
                                            name="address"
                                            value={student.address}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="health_status">Health Status:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="health_status"
                                            name="health_status"
                                            value={student.health_status}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fee">Fee:</label>
                                        <input
                                            type="number"
                                            step="0.01"
                                            className="form-control"
                                            id="fee"
                                            name="fee"
                                            value={student.fee}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    {error && <p style={{ color: 'red' }}>Error: {error}</p>}
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary">
                                            Save Changes
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary ml-2"
                                            onClick={handleEditToggle}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            ) : (
                                <div>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>Name:</td>
                                                <td>{student.name}</td>
                                            </tr>
                                            <tr>
                                                <td>Date Registered:</td>
                                                <td>{student.date_reg}</td>
                                            </tr>
                                            <tr>
                                                <td>Number:</td>
                                                <td>{student.number}</td>
                                            </tr>
                                            <tr>
                                                <td>Guardian Number:</td>
                                                <td>{student.guardian_number}</td>
                                            </tr>
                                            <tr>
                                                <td>Level:</td>
                                                <td>{student.level}</td>
                                            </tr>
                                            <tr>
                                                <td>Date of Birth:</td>
                                                <td>{student.dob}</td>
                                            </tr>
                                            <tr>
                                                <td>Address:</td>
                                                <td>{student.address}</td>
                                            </tr>
                                            <tr>
                                                <td>Health Status:</td>
                                                <td>{student.health_status}</td>
                                            </tr>
                                            <tr>
                                                <td>Fee:</td>
                                                <td>{student.fee}</td>
                                            </tr>
                                            <tr>
                                                <td>File Upload:</td>
                                                <td>
                                                    {student.file_upload ? (
                                                        <a href={student.file_upload} target="_blank" rel="noopener noreferrer">View File</a>
                                                    ) : 'No File'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Citizen:</td>
                                                <td>{student.citizen}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="text-center">
                                        {userRole !== 'accountant' && (
                                            <button className="btn btn-danger" onClick={handleDelete}>
                                                Delete
                                            </button>
                                        )}
                                        <button className="btn btn-primary ml-2" onClick={handleEditToggle}>
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentDetails;
