import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface Student {
  id: number;
  name: string;
}

interface Examination {
  id: number;
  exam_name: string;
}

interface Mark {
  student: number;
  examination: number;
  file: File | null;
}

const AddMark: React.FC = () => {
  const [students, setStudents] = useState<Student[]>([]);
  const [examinations, setExaminations] = useState<Examination[]>([]);
  const [newMark, setNewMark] = useState<Mark>({student: 0, examination: 0, file: null });
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const studentResponse = await axios.get<any>('https://api.bayaanedu.com/Branches/students/');
        console.log('Student Response:', studentResponse.data);
        const studentsData: Student[] = studentResponse.data.results.map((student: any) => ({
          id: student.id,
          name: student.name,
        }));
        setStudents(studentsData);

        const examResponse = await axios.get<any>('https://api.bayaanedu.com/exams/exams/');
        console.log('Exam Response:', examResponse.data);  // Log the entire exam response
        const examsData: Examination[] = examResponse.data.map((exam: any) => ({
          id: exam.id,
          exam_name: exam.exam_name,
        }));
        setExaminations(examsData);
        console.log('Examinations Data:', examsData);  // Log the processed exams data
      } catch (error) {
        // console.error('Fetch Data Error:', error.message);
        setError('Failed to fetch data');
      }
    };

    fetchData();
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setNewMark({ ...newMark, file: event.target.files[0] });
    }
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('student', newMark.student.toString());
    formData.append('examination', newMark.examination.toString());
    if (newMark.file) {
      formData.append('file', newMark.file);
    }

    try {
      await axios.post('https://api.bayaanedu.com/exams/marks/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSuccessMessage('Mark added successfully');
      setNewMark({student: 0, examination: 0, file: null });
      setTimeout(() => setSuccessMessage(null), 3000);  // Remove success message after 3 seconds
    } catch (error) {
      setError('Failed to add mark');
    }
  };

  return (
    <div className="container mt-5" style={{ marginLeft: "200px", width: "800px" }}>
      <h1 className="mb-4">Add Mark</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Student</label>
          <select
            className="form-control"
            value={newMark.student}
            onChange={e => setNewMark({ ...newMark, student: parseInt(e.target.value) })}
          >
            <option value="">Select Student</option>
            {students.map(student => (
              <option key={student.id} value={student.id}>
                {student.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Examination</label>
          <select
            className="form-control"
            value={newMark.examination}
            onChange={e => setNewMark({ ...newMark, examination: parseInt(e.target.value) })}
          >
            <option value="">Select Examination</option>
            {examinations.map(exam => (
              <option key={exam.id} value={exam.id}>
                {exam.exam_name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>File</label>
          <input
            type="file"
            className="form-control"
            onChange={handleFileChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">Add Mark</button>
      </form>
    </div>
  );
};

export default AddMark;
