import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Register from './Components/Register';
import Login from './Components/Login';
import { Reset } from './Components/Reset';
import { Forgot } from './Components/Forgot';
import BranchForm from './Components/Branches/BranchForm';
import BranchList from './Components/Branches/BranchList';
import Sidebar from './Components/Sidebar';
import Dashboard from './Components/Dashboard';
import StudentTable from './Components/Students/StudentTable';
import StudentCountComponent from './Components/Students/StudentTable';
import { Nav } from './Components/Nav';
import StudentCount from './Components/Students/StudentTable';
import StudentDetails from './Components/Students/StudentDetails';
import UpdateStudent from './Components/Students/UpdateStudent';
import AddStudent from './Components/Students/AddStudent';
import UserComponent from './Components/UserComponent';
import TeachersComponent from './Components/Teachsers/TeachersComponent';
import AddTeachersComponent from './Components/Teachsers/AddTeachersComponent';
import ShowFees from './Components/fees/ShowFees';
import DetailFees from './Components/fees/DetailFees';
import AddFees from './Components/fees/AddFees';
import IncomeExpenseSummary from './Components/Income/IncomeExpenseSummary';
import ExpenseList from './Components/Expenses/ExpenseList';
import ExpenseForm from './Components/Expenses/ExpenseForm';
import Salaries from './Components/Salaries/Salaries';
import AddSalaryForm from './Components/Salaries/AddSalaryForm';
import FeesNotPaid from './Components/fees/FeesNotPaid';
import ShowExams from './Components/exams/ShowExams';
import AddExam from './Components/exams/AddExam';
import Marks from './Components/exams/ShowMarks';
import AddMark from './Components/exams/AddMark';
import Mustaqbal from './Components/Branches/Mustaqbal';
import Bayan from './Components/Branches/Bayan';
import ShowClass from './Components/Classes/ShowClass';
import AddClass from './Components/Classes/AddClass';
import UserAccount from './Components/UserAccount';

function App() {
    return (
      <BrowserRouter>
      <Nav/>
        <Sidebar/>
            <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/add-branches" element={<BranchForm />} />
                <Route path="/branches" element={<BranchList />} />
                {/* Students */}
                <Route path="/students" element={<StudentCount/>} />
                <Route path='/student-details/:id' element={<StudentDetails />} />
                <Route path="/students/:id/update" element={<UpdateStudent />} />
                <Route path="/add-students" element={<AddStudent />} />
                {/* Users */}
                <Route path="/" element={<Login />} />
                <Route path="/reset/:token" element={<Reset/>}/>
                <Route path="/forgot" element={<Forgot />} />
                <Route path="/register" element={<Register/>} />
                <Route path="/users" element={<UserComponent />} />
                <Route path="/account" element={<UserAccount />} />
                {/* Teachers */}
                <Route path="/teachers" element={<TeachersComponent />} />
                <Route path="/add-teachers" element={<AddTeachersComponent />} />
                {/* financials */}
                <Route path="/fees" element={<ShowFees/>} />
                <Route path="/add-fees" element={<AddFees/>} />
                <Route path='/:id/' element={<DetailFees/>} />
                <Route path="/income" element={<IncomeExpenseSummary/>} />
                <Route path="/ExpenseList" element={<ExpenseList/>} />
                <Route path="/expenses" element={<ExpenseForm/>} />
                <Route path="/salaries" element={<Salaries/>} />
                <Route path="/fees_not_paid" element={<FeesNotPaid/>} />
                <Route path="/add-salary" element={<AddSalaryForm/>} />
                {/* Exams  */}
                <Route path="/exams" element={<ShowExams/>} />
                <Route path="/marks" element={<Marks/>} />
                <Route path="/add-marks" element={<AddMark/>} />
                <Route path="/add-exams" element={<AddExam onSuccess={() => {}} />}/>
                    {/* Branches */}
                <Route path="/mustaqbal" element={<Mustaqbal/>} />
                <Route path="/bayan" element={<Bayan/>} />
                {/* Classes */}
                <Route path="/classes" element={<ShowClass/>} />
                <Route path="/add-classes" element={<AddClass/>} />
                
            </Routes>
        </BrowserRouter>
    );
}

export default App;
