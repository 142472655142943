import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { setAuth } from '../Redux/authSlice';
import { RootState } from '../Redux/store';
import { useSelector, useDispatch } from 'react-redux';

const ExpenseList: React.FC = () => {
  const [expenses, setExpenses] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', role: '' });
  const auth = useSelector((state: RootState) => state.auth.value);

  const getCookie = (name: string) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  };

  const fetchData = async () => {
    try {
      const token = getCookie('token');
      if (!token) {
        dispatch(setAuth(false));
        return;
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const { data } = await axios.get('https://api.bayaanedu.com/users/user', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setUserInfo({ firstName: data.first_name, lastName: data.last_name, role: data.role });
      console.log('User Info:', data);
      dispatch(setAuth(true));
    } catch (e) {
      console.error('Error fetching user data:', e);
      dispatch(setAuth(false));
    }
  };
  

  const fetchExpenses = async (page: number = 1) => {
    try {
      const response = await axios.get(`https://api.bayaanedu.com/fees/expenses/?page=${page}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setExpenses(response.data.results); // Assuming the paginated results are in `results`
      setTotalPages(response.data.total_pages); // Assuming the total number of pages is returned as `total_pages`
      setCurrentPage(page);
      console.log('Expenses:', response.data);
    } catch (error) {
      console.error('Error fetching expenses:', error);
    }
  };

  const handleDeleteExpense = async (id: number) => {
    if (window.confirm('Are you sure you want to delete this expense?')) {
      try {
        const token = getCookie('token');
        if (!token) {
          dispatch(setAuth(false));
          return;
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        
        await axios.delete(`https://api.bayaanedu.com/fees/expenses/${id}/`);
        
        // Remove the deleted expense from the state
        setExpenses(expenses.filter(expense => expense.id !== id));
        
        alert('Expense deleted successfully.');
      } catch (error) {
        console.error('Error deleting expense:', error);
        alert('Error deleting expense. Please try again.');
      }
    }
  };

  const handlePageChange = (page: number) => {
    fetchExpenses(page);
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (auth) {
      fetchExpenses();
    }
  }, [auth]);

  return (
    <>
      <div className="container-fluid mt-2">
        {auth ? (
          <div className="container" style={{ maxWidth: '770px' }}>
            <h4 className="text-center">Expenses List</h4>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Expense Type</th>
                    <th scope="col">Cost</th>
                    <th scope="col">Date Paid</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {expenses.length === 0 ? (
                    <tr>
                      <td colSpan={5} className="text-center">No data available</td>
                    </tr>
                  ) : (
                    expenses.map((expense, index) => (
                      <tr key={expense.id}>
                        <th scope="row">{(currentPage - 1) * 15 + index + 1}</th>
                        <td>{expense.Expenses_type}</td>
                        <td>ksh {expense.Cost}</td>
                        <td>{expense.date_paid_expenses}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => handleDeleteExpense(expense.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-primary"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>Page {currentPage} of {totalPages}</span>
              <button
                className="btn btn-primary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <div className="container mt-4">
            <h3>You are not authenticated or authorized to view this page</h3>
          </div>
        )}
      </div>
    </>
  );
};

export default ExpenseList;
