import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { RootState } from '../Redux/store';
import { useSelector } from 'react-redux';
import { setAuth } from '../Redux/authSlice';

const ExpenseForm: React.FC = () => {
    const [expenseType, setExpenseType] = useState<string>('');
    const [cost, setCost] = useState<number | ''>('');
    const [datePaid, setDatePaid] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', role: '' }); // Added role in userInfo state
    const auth = useSelector((state: RootState) => state.auth.value);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Validation
        if (!expenseType || !cost || !datePaid) {
            setErrorMessage('All fields are required');
            return;
        }

        try {
            // Send expense data to backend
            const response = await axios.post<{ message: string }>('https://api.bayaanedu.com/fees/expenses/', {
                Expenses_type: expenseType,
                Cost: parseInt(cost as unknown as string), // Convert to 'unknown' first
                date_paid_expenses: datePaid
            });

            // Handle success
            console.log('Expense created:', response.data);
            // Clear form fields
            setExpenseType('');
            setCost('');
            setDatePaid('');
            setErrorMessage('');
            window.location.href = "/ExpenseList"
        } catch (error) {
            // Handle error
            console.error('Error creating expense:', error);
            setErrorMessage('Error creating expense');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Retrieve token from cookie
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }
                // Set token in Axios default headers
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                // Fetch user data
                const { data } = await axios.get('https://api.bayaanedu.com/users/user'); // Fetching user information including role
                setUserInfo({ firstName: data.first_name, lastName: data.last_name, role: data.role }); // Setting user information including role
                // Set authentication status in Redux store
                dispatch(setAuth(true));
            } catch (e) {
                dispatch(setAuth(false));
            }
        };
        fetchData();
    }, [dispatch]);
    // Function to get cookie by name
    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };
    
    return (
        <>
        <div className="container">
            {auth ? (
                <div>
                    <div className="row justify-content-center mt-5">
                        <div className="col-md-6">
                            <div className="card" style={{backgroundColor:"white"}}>
                                <div className="card-body">
                                    <h2 className="card-title">Create Expense</h2>
                                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-2">
                                            <label htmlFor="expenseType" className="form-label">Expense Type:</label>
                                            <input type="text" id="expenseType" className="form-control form-control-lg" value={expenseType} onChange={(e) => setExpenseType(e.target.value)} />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="cost" className="form-label">Cost:</label>
                                            <input type="number" id="cost" className="form-control form-control-lg" value={cost} onChange={(e) => setCost(parseFloat(e.target.value))} style={{height:"10px"}}/>
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="datePaid" className="form-label">Date Paid:</label>
                                            <input type="date" id="datePaid" className="form-control form-control-lg" value={datePaid} onChange={(e) => setDatePaid(e.target.value)} />
                                        </div>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <h3>You are not authenticated or authorized to view this page</h3>
                </div>
            )}
        </div>
        </>

    );
};

export default ExpenseForm;
