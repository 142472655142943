import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface Mark {
  id: number;
  branch: string;
  student_name: string;
  examination_name: string;
  file: string;
}

const Marks: React.FC = () => {
  const [marks, setMarks] = useState<Mark[]>([]);
  const [editMark, setEditMark] = useState<Mark | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [userRole, setUserRole] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchMarks = async () => {
      try {
        const response = await axios.get<any>('https://api.bayaanedu.com/exams/marks/', {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setMarks(response.data);
      } catch (error) {
        setError('Failed to fetch marks');
      }
    };
    const fetchUserRole = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get('https://api.bayaanedu.com/users/user');
        setUserRole(data.user.role);
        setAuthenticated(true);
        console.log('Logged in user role:', data.user.role); // Log user role
      } catch (error) {
        console.error('Failed to fetch user role:', error);
        setError('Failed to fetch user role');
      } finally {
        setLoading(false);
      }
    };
    fetchMarks();
    fetchUserRole();
  }, []);

  const handleEdit = (mark: Mark) => {
    setEditMark(mark);
  };

  const handleDelete = async (id: number) => {
    if (!['admin', 'super_admin'].includes(userRole)) {
      // If user is not admin or super_admin, prevent deletion
      setError('You do not have permission to delete marks.');
      return;
    }

    if (window.confirm('Are you sure you want to delete this mark?')) {
      try {
        await axios.delete(`https://api.bayaanedu.com/exams/marks/${id}/`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setMarks(marks.filter(mark => mark.id !== id));
        setSuccessMessage('Mark deleted successfully');
        setTimeout(() => setSuccessMessage(null), 3000);
      } catch (error) {
        setError('Failed to delete mark');
      }
    }
  };

  const handleSave = async () => {
    if (editMark) {
      try {
        await axios.put(`https://api.bayaanedu.com/exams/marks/${editMark.id}/`, editMark, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setMarks(marks.map(mark => (mark.id === editMark.id ? editMark : mark)));
        setEditMark(null);
        setSuccessMessage('Mark updated successfully');
        setTimeout(() => setSuccessMessage(null), 3000);
      } catch (error) {
        setError('Failed to save mark');
      }
    }
  };

  return (
    <div className="container mt-1">
      <h1 className="mb-2" style={{textAlign:"center"}}>Exam Marks</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      {editMark && (
        <div className="mb-4">
          <h2>Edit Mark</h2>
          <div className="form-group">
            <label>Branch</label>
            <input
              type="text"
              className="form-control"
              value={editMark.branch}
              onChange={e => setEditMark({ ...editMark, branch: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Student Name</label>
            <input
              type="text"
              className="form-control"
              value={editMark.student_name}
              onChange={e => setEditMark({ ...editMark, student_name: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label >Examination Name</label>
            <input
              type="text"
              className="form-control"
              value={editMark.examination_name}
              onChange={e => setEditMark({ ...editMark, examination_name: e.target.value })}
            />
          </div>
          <button className="btn btn-primary" onClick={handleSave}>Save</button>
          <button className="btn btn-secondary ml-2" onClick={() => setEditMark(null)}>Cancel</button>
        </div>
      )}
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Student</th>
              <th scope="col">Branch</th>
              <th scope="col">Examination</th>
              <th scope="col">File</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {marks?.map((mark, index) => (
              <tr key={index}>
                <td>{mark.student_name}</td>
                <td>{mark.branch}</td>
                <td>{mark.examination_name}</td>
                <td><a href={mark.file} target="_blank" rel="noopener noreferrer">Download File</a></td>
                <td>
                  <button className="btn btn-warning btn-sm mr-2" onClick={() => handleEdit(mark)}>Edit</button>
                  {['admin', 'super_admin'].includes(userRole) && (
                    <button className="btn btn-danger btn-sm" onClick={() => handleDelete(mark.id)}>Delete</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Marks;
