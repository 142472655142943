import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

interface MonthlyData {
    total_income: string;
    total_expenses: string;
    net_income: string;
}

const Charts: React.FC = () => {
    const [incomeData, setIncomeData] = useState<{ month: string; total_income: number }[]>([]);
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const response = await axios.get('https://api.bayaanedu.com/users/user');
                const token = response.data.token; // Assuming the token is returned as { token: "your_token" }
                setToken(token);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            } catch (error) {
                console.error('Error fetching token:', error);
            }
        };
        fetchToken();
    }, []);

    useEffect(() => {
        const fetchIncomeData = async () => {
            try {
                const response = await axios.get<{ income_and_expenses_by_month: Record<string, MonthlyData> }>(
                    'https://api.bayaanedu.com/fees/income/'
                );
                const transformedIncomeData = Object.entries(response.data.income_and_expenses_by_month).map(([month, data]) => ({
                    month,
                    total_income: parseFloat(data.total_income.replace(',', '')),
                }));
                setIncomeData(transformedIncomeData);
            } catch (error) {
                console.error('Error fetching income data:', error);
            }
        };

        fetchIncomeData();
    }, []);

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-12">
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <h5 className="card-title text-center mb-4">Income Trend</h5>
                            <div style={{ width: '100%', height: 400 }}>
                                <ResponsiveContainer>
                                    <LineChart data={incomeData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="month" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="total_income" stroke="#8884d8" activeDot={{ r: 8 }} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Charts;
